import React, {Fragment, useEffect} from 'react';
import {
    BrowserRouter as Router
} from 'react-router-dom';
import {connect} from 'react-redux'
import {AuthRoutes} from '@router';
import {AuthActions} from '@redux';


/* eslint-disable jsx-a11y/anchor-has-content */

const AuthApp = ({GetSettings}) => {
    useEffect(() => {
        GetSettings()
    }, [GetSettings]);

    return (
        <Router basename="/authentication/account">
            <Fragment>
                <div className="b-form-container">
                    <div className="b-form-wrapper">
                        <a className="b-logo" href="/"/>
                        <div className="b-form">
                            <div className="b-login-form-container">
                                {AuthRoutes}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </Router>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        loginFB: store.loginFB
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        GetSettings: () => dispatch(AuthActions.GetSettings())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthApp);
